<template>
  <div>
     <div class="page_booking">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
            <div>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevrons-left" class="dateBtn" v-on:click="addDay(-7)"></vs-button>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" class="dateBtn" v-on:click="addDay(-1)"></vs-button>
            </div>
            <div class="text-center cursor click selector_date">
                <span v-on:click="toDay()">Aujourd'hui</span><br/>
                <cDate v-model="date" ref="cDate" />
            </div>
            <div>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" class="dateBtn" v-on:click="addDay(1)"></vs-button>
                <vs-button color="primary" type="border" icon-pack="feather" icon="icon-chevrons-right" class="dateBtn" v-on:click="addDay(7)"></vs-button>
            </div>
            </vs-col>
        </vs-row>
        </div>
        <br>
        <br>
      <template v-if="this.list.cours.length!=0">
            <!-- <vs-button @click="info"> Test </vs-button> -->
            <div class="container">
              <div v-for="(i,index) in (0,list.cours.length)" :key="index">
                    <vs-col vs-w="12" v-if="i === -1 || list.debut[i-1] != list.debut[i-2]" >
                      <h2 class="text-center pt-5 text-primary">{{list.debut[i-1]}}</h2>
                    </vs-col>
                    <group :uid="list.uids[i-1]" :dates="date" :coach="list.coach[i-1]" :cours="list.cours[i-1]" :debut="list.debut[i-1]" :fin="list.fin[i-1]" :categorie="list.categorie[i-1]" :participant="list.participant[i-1]" :color="list.colors[i-1]" ></group>
              </div>
            </div>
      </template>
      <vx-card v-else>
        <p> Aucun cours aujourd'hui </p>
      </vx-card>

  </div>
</template>

<script>

import group from '@/components/booking/group'
import VxCard from '@/components/vx-card/VxCard.vue'
import autoRefresh from '@/assets/utils/autoRefresh'
import Item  from '@/database/models/item';
import Machine  from '@/database/models/machine';
import BookingHour  from '@/database/models/booking_hour';
import Member  from '@/database/models/member';
import moment from 'moment'
import cDate from '@/components/app/date'
import Booking  from '@/database/models/booking';


export default {
  components:{
    group,
    VxCard,
    Item,
    Machine,
    BookingHour,
    Member,
    cDate,
    Booking
  },
  watch:{
        $route (){
            if(this.$route.params.date != undefined)
                this.date = new Date( this.$route.params.date.replace(/-/g, "/") );
            autoRefresh.refresh();
        },
        date:function(){

            let d = (this.date.getMonth()+1)+'-'+this.date.getDate()+'-'+this.date.getFullYear();
            if( d != this.$route.params.date )
                this.$router.push('/admin/groups/'+d);
            
            
            autoRefresh.SetCallback(async()=>
            {
                await this.getMem(this.date);
               
            
            })
            autoRefresh.refresh();
            
            
        }
  },
  data(){
            return{
              date :new Date(),
              list:{
                coach:[],
                categorie:[],
                cours:[],
                debut:[],
                fin:[],
                participant:[],
                colors:["blue","green","orange"],
                dates:[],
                uids:[]
              },
              n:3

            }
    },
  mounted:function()
	{
        autoRefresh.SetCallback(async()=>
        {
            await this.getMem(this.date)
           
        })
        autoRefresh.refresh();
  },
  methods:{
    
    async getMem(dateselected){
     

     
      let today=moment(dateselected,"").format("YYYY-MM-DD");
      
        // je recupere la list des machines de type g 
        let listG=[];
        const listUidR=[];
        
        //console.log(" ->> ", dateselected)
        let machines = await Machine.AwaitGetAllMemory();
        for (let j in machines){
              let deb = moment(machines[j].debut,"").format("YYYY-MM-DD")
              let fin ;
              
              if( machines[j].fin!=null ){
                fin=moment(machines[j].fin,"").format("YYYY-MM-DD");

              } 
              else{
                fin=null;
              }
              
              
              // moment(today.toString()).isBetween(deb.toString(),fin.toString())
              if (machines[j].afftype=="g" && machines[j].active==1 ){
                if( machines[j].fin!=null ){
                  
                  if( moment(today.toString()).isBetween(deb.toString(),fin.toString())){
                    // console.log(" -- ", deb," ", machines[j].name )
                    listG.push(machines[j])

                  }
                  else if (  moment(today.toString()).isSame(deb.toString())   ){
                    // console.log(" 2 ", machines[j].name)
                    listG.push(machines[j])
                  }
                  else if ( moment(today.toString()).isSame(fin.toString())  ){
                    // console.log(" 3 ", machines[j].name, " deb  ", deb , " fin ", fin)
                    listG.push(machines[j])
                  }
                 
                }
                else if ((moment(today.toString()).isAfter(deb.toString()) || moment(today.toString()).isSame(deb.toString())) && machines[j].fin==null ){
                  // console.log(" 4 ", machines[j].name)
                  listG.push(machines[j])
                }
                
                
              }
            
        }
        // console.log(" list groups ", listG)
       
        // je recupere les heures et la list des machines 
        let Allhours = await BookingHour.AwaitGetAllMemory();
        
        var listDeb=[]
        let infos =[]
        let listMachine = []
        var auxs=[]
       
        for (let i in Allhours){
          if (Allhours[i].flag != 'e')
            for(let j in listG){
              if(Allhours[i].machine==listG[j].uid && Allhours[i].day==dateselected.getDay()){
                
                listMachine.push(Allhours[i]);
                listDeb.push(Allhours[i].hour)
                auxs.push(Allhours[i].hour)
                listUidR.push(Allhours[i].uid);
                infos.push(listG[j].info)
                break;
              }
            }
        }

        
        // je recupere la list des coachs
        let members = await Member.AwaitGetAllMemory();
        //console.log(" members : ", members)
        let listCoah=[];
        for(let i in listMachine ){
          if(listMachine[i].coach==null){
              
                listCoah.push("Aucun Coach")
          }
          else{
            for (let j in members){
                if (listMachine[i].coach==members[j].uid ){
                  let aux = members[j].last_name+" "+members[j].first_name;
                  listCoah.push(aux)
                  break;
                }
              
            }
          }
        }

        // je calcul l'heure de fin de chaque séance 
        let listFin=[];
      //  console.log(" list G ", listG, " aux ", auxs, " list machine ", listMachine)

        for(let i in listMachine){
          let duree = listMachine[i].duration.split(':')
          //  console.log(" durée : ", duree)
          let h = duree[0];
          let mm = duree[1];
          const aux = moment(listDeb[i],"HH:mm").format("HH:mm");
          //console.log(" hh ",h,"mm ",mm)
          if(h=="00"){
            // console.log("o", duree)
            let au = moment(aux,"HH:mm").add(parseInt(mm),"minutes").format("HH:mm")
            listFin.push(au)
          }else{
             let au = moment(aux,"HH:mm").add(parseInt(h),"hour").format("HH:mm")
              // console.log(" aux 1", au, " aux j", aux, " h ", h)
            if(mm!="00"){
            //  console.log(" aux 2: ", au," ", mm, " ", h)
               au = moment(au,"HH:mm").add(parseInt(mm),"minutes").format("HH:mm")
              //  console.log(" aux : ", au," ", mm, " ", h)
              listFin.push(au)
            }
            else{
               listFin.push(au)
            }

            
             
          }
        }

        
        // je recupere la liste des noms des cours et les couleurs qui correspondent
        let nom=[];
        let colorr=[];
        for(let i in listMachine){
          for(let j in listG){
            if(listMachine[i].machine==listG[j].uid){
                nom.push(listG[j].name);
                colorr.push(listG[j].color)
                
                break;
            }
              
          }
        }

        let allRes = await Booking.AwaitGetAllMemory();
        
        
          // je calcule le nbr de participant et les credit de chaquer 
         let listPart=[]
         let listCr=[]
          for (let j in listMachine){
            // console.log(" maci ", listMachine[j])
            listCr.push(listMachine[j].uid)
            let cpt=0;
            for (let i in allRes){
                //console.log(" all res : ",allRes)
                let cday = allRes[i].date.getDay()
               // let dday = allRes[i].date.getDate()
                //console.log(" d ", dday, " date : ", this.date)
                let cmach = allRes[i].machine;
                let hRes = moment(allRes[i].date,"").format("HH:mm");
                // on verifie que la machine est la bonne et qu'il s'agit du bon jour 
                if(cmach==listMachine[j].machine && cday==listMachine[j].day ){
                  //on verifie que l'heure de reservation de cette machine est la meme
                  let date = moment(this.date,"").format("MM-DD-YYYY")
                  let dateR = moment(allRes[i].date,"").format("MM-DD-YYYY")
                    
                    if(listMachine[j].hour==hRes && date== dateR ){
                        cpt++;
                    }
                    
                }
            }
            listPart.push(cpt)
           
          }
    
       
        //  console.log(" lis ", listFin)
       
        //pour metttre la listre dans l'ordre croissant
      
        auxs.sort()
        
        


        // console.log(" uid list ", listUidR)

        let listCoahL=[];
        let infosL=[];
        let listFinL=[];
        let listPartL=[];
        let colorrL=[];
        let listCrL=[];
        let nomL=[];
        const uidL=[];
        // console.log(" nom j", nom)

        let listJ=[];
      for (let i in auxs){
        for ( let j in listDeb){
         // console.log(" j ", j , " time : ", auxs[i], " deb ", listDeb[j])
          
          //  console.log(" index",this.getIndex(uidL, listUidR[i]))
          if(listDeb[j]==auxs[i] && listJ.indexOf(j)==-1){
            
            uidL.push(listUidR[i])
            listJ.push(j)
            nomL.push(nom[j])
            listCoahL.push(listCoah[j])
            infosL.push(infos[j])
            listFinL.push(listFin[j])
            listPartL.push(listPart[j])
            colorrL.push(colorr[j])
            listCrL.push(listCr[j])
            break;
          }
          
        }
      }
        this.list.coach=listCoahL;
        this.list.categorie=infosL;
        this.list.fin=listFinL;
        this.list.participant=listPartL;
        this.list.colors=colorrL;
        this.date=dateselected;
        this.list.uids=listCrL;
        this.list.cours=nomL;
        this.list.debut=auxs;

        

        listCoah =[]
        infos=[]
        listDeb=[]
        listFin=[]
        nom=[]
        listPart=[]
        colorr=[]
        listCr=[]
       

    },
    addDay( nb )
        {
            let d = new Date( this.date )
            d.setDate(d.getDate() + nb)
            
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            if( fd != this.$route.params.date )
                this.$router.push('/admin/groups/'+fd).catch(()=>{})
        },
        toDay()
        {
            let d = new Date()
            
            let fd = ( d.getMonth()+1)+'-'+d.getDate()+'-'+d.getFullYear();
            if( fd != this.$route.params.date )
                this.$router.push('/admin/groups/'+fd).catch(()=>{})     
        },
       
  }
}
</script>



<style lang="scss" scoped>

.header-sidebar 
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        > button {
            margin-left: 10px;
        }
    }
}
.header-body{
    margin:20px;
}

.footer-sidebar
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    > button {
        border: 0px solid rgba(0, 0, 0, 0) !important;
        border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
        border-radius: 0px !important;
    }
}
.btn_resa .vs-button{
    margin-bottom:10px;
}
.dateBtn{
    display:inline !important;
    margin:5px;
}
.vdp-datepicker input{
    text-align:center;
}



</style>